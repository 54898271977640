import React from 'react'

export default function ProfileInformation() {
    return (
        <section className="main-component">
            <div className="title">Jordan Kline</div>
            <div className="subtitle">Software Engineer </div>
        </section>
    )
}

