import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../theme.js";

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>{children}</main>
    </ThemeProvider>
  );
}
