import React, { useState } from "react";
import { Instagram, GitHub, LinkedIn, Email, Lightbulb } from "@mui/icons-material";
import { analytics } from "./App";
import { logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";

const themes = [
  '',
  'brutalist',
  'nineties',
];

function SocialLinks() {
  const navigate = useNavigate();
  const [showToggleTheme, setShowToggleTheme] = useState(false);

  const handleSocialClick = (type) => {
    logEvent(analytics, "social_click", {
      type,
    });
  };

  const toggleTheme = () => {
    handleSocialClick("change_theme");

    const randomIndex = Math.floor(Math.random() * themes.length);
    navigate(`/${themes[randomIndex]}`);
  };

  return (
    <section className="social-links" onDoubleClick={() => setShowToggleTheme(true)}>
      <div
        className="hover-return"
        onClick={() => handleSocialClick("instagram")}
      >
        <a
          href="https://instagram.com/jordaninthewind"
          target="_blank"
          title="Instagram"
          rel="noopener noreferrer"
        >
          <Instagram fontSize="large" />
        </a>
      </div>
      <div
        className="hover-return"
        onClick={() => handleSocialClick("github")}
      >
        <a
          href="https://github.com/jordaninthewind"
          className="hover-return"
          target="_blank"
          title="Github"
          rel="noopener noreferrer"
        >
          <GitHub fontSize="large" />
        </a>
      </div>
      <div
        className="hover-return"
        onClick={() => handleSocialClick("linkedin")}
      >
        <a
          href="https://linkedin.com/in/jordan-kline"
          className="hover-return"
          target="_blank"
          title="Linkedin"
          rel="noopener noreferrer"
        >
          <LinkedIn fontSize="large" />
        </a>
      </div>
      <div
        className="hover-return"
        onClick={() => handleSocialClick("email")}
      >
        <a
          href="mailto:jordan.kline@gmail.com"
          className="hover-return"
          title="E-mail"
          rel="noopener noreferrer"
        >
          <Email fontSize="large" />
        </a>
      </div>
      {showToggleTheme && (
        <div className="hover-return"
          onClick={toggleTheme}
          role="button"
        >
          <Lightbulb fontSize="large" />
        </div>)}
    </section >
  );
}

export default SocialLinks;
