import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Home from "./Home";

import "../App.scss";

const firebaseConfig = {
  apiKey: "AIzaSyByPd4mAHyUKvQJFt7fk2ZDCu725jfkM7c",
  authDomain: "profile-9d519.firebaseapp.com",
  projectId: "profile-9d519",
  storageBucket: "profile-9d519.appspot.com",
  messagingSenderId: "392835290759",
  appId: "1:392835290759:web:672aa669adf882926a0a22",
  measurementId: "G-SV7XN084JG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/brutalist" element={<Home variant="brutalist" />} />
        <Route exact path="/nineties" element={<Home variant="nineties" />} />
      </Routes>
    </Router>
  );
}

export default App;
